<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <video src="../assets/ingenieria2.mp4" type="video" autoplay loop muted></video>
      <div class="banner text-center">
        <div class="sticky-top">
          <h1>INGENIERÍA ELÉCTRICA</h1>
          <h3>Descubre soluciones eléctricas profesionales para tu empresa o proyecto por medio de nuestro gran aliado estratégico el cual cuenta con una alta experiencia en el sector</h3>
          <a href="#Go" class="btn">Vamos</a>
        </div>
      </div>
    </div>
    <!-- Que es Ingeniería -->
    <div id="Go" class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">¿Que es Ingeniería Eléctrica?</h1>
        </div>
      </div>
      <div class="row servicio align-items-center ">
        <div class="col-12 col-md-6 fondo">
          <h4 class="mt-3">Es una rama de la ingeniería que se ocupa del estudio, diseño, desarrollo y aplicación de sistemas y dispositivos eléctricos. Esta disciplina se enfoca en la generación, transmisión, distribución y utilización de la energía eléctrica, así como en el diseño y análisis de circuitos eléctricos y electrónicos</h4>
        </div>
        <div class="col-12 col-md-6" style="margin: 0; padding: 0; height: 356px;">
          <iframe width="100%" height="100%" allowfullscreen src="https://www.youtube.com/embed/ffvtxGiQh2U">
          </iframe>
        </div>
      </div>
    </div>
    <!-- Servicios de Eléctrica -->
    <div class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">Servicios de Eléctrica</h1>
        </div>
      </div>
      <div class="row servicio align-items-center">
        <div class="col-12 col-md-6 fondo">
          <h2>Estudios Eléctricos</h2>
          <h4>Realizamos análisis detallados de las instalaciones eléctricas, identificando posibles problemas, riesgos y oportunidades de mejora</h4>
          <router-link to="/Estudios">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner estudios">
        </div>
        <div class="col-12 col-md-6 banner paneles desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Paneles Solares</h2>
          <h4>Soluciones para generar tu propia energía renovable, reducir costos en tu factura eléctrica y contribuir al cuidado del medio ambiente</h4>
          <router-link to="/Paneles">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner paneles movil">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Líneas de Transmisión</h2>
          <h4>Nuestro equipo de expertos cuenta con amplia experiencia en diseño, instalación, mantenimiento y monitoreo de líneas de transmisión eléctrica</h4>
          <router-link to="/Transmision">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner transmision">
        </div>
        <div class="col-12 col-md-6 banner subestacion desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Subestaciones</h2>
          <h4>Nos comprometemos a proporcionar soluciones personalizadas y seguras, cumpliendo con los estándares y regulaciones más exigentes</h4>
          <router-link to="/Subestaciones">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner subestacion movil">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Electrica",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  height: 82vh
}

video {
  width: 100%;
  height: 82vh;
  filter: brightness(70%);
  position: absolute;
  object-fit: fill;
}

.banner h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 80px;
  padding-top: 7%;
  animation: zoomFont 1s alternate infinite;
}

.banner h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 35px;
  margin: 3% auto;
  width: 70%;
}

.banner a {
  width: 10%;
  margin-top: 2%;
}

.gradient-orange {
  background: linear-gradient(45deg, #df7000, #ff8913, #ffa74f);
  background-size: 200%;
  animation: gradient 2s ease infinite;
  height: 20vh;
}

.gradient-orange h1 {
  font-size: 40px;
}

.banner {
  height: 50vh;
}

.servicio h2 {
  font-size: 50px;
  padding-top: 3%;
}

.servicio .fondo,
.servicio .banner {
  background-image: url(../assets/servicio-fondo.png);
  background-size: 100% 100%;
  height: 50vh;
  padding: 2%;
}

.servicio .fondo {
  animation: service 1s infinite;
}

.servicio .estudios {
  background-image: url(../assets/servicio-estudios.gif);
  animation: zoom 3.6s infinite;
}

.servicio .paneles {
  background-image: url(../assets/servicio-paneles.gif);
  animation: zoom 3s infinite;
}

.servicio .transmision {
  background-image: url(../assets/servicio-transmision.gif);
  animation: zoom 3.8s infinite;
}

.servicio .subestacion {
  background-image: url(../assets/servicio-subestacion.gif);
  animation: zoom 4s infinite;
}

@media only screen and (max-width: 990px) {
  #wrapper {
    align-items: center;
    display: flex;
  }

  .banner h1 {
    font-size: 40px;
    padding: 0;
    margin-top: -10%;
  }

  .banner h3 {
    font-size: 23px;
    margin: 10% auto;
    width: 90%;
  }

  .banner a {
    display: inline;
  }

  .servicio h2 {
    font-size: 40px !important;
    line-height: 1;
    padding-top: 8%;
  }

  .servicio .trading {
    background-image: url(../assets/servicio-trading2.gif);
  }

  .servicio .viajes {
    background-image: url(../assets/servicio-viajes2.gif);
  }

  .servicio .marketing {
    background-image: url(../assets/servicio-marketing-online2.gif);
  }

  .servicio .bienestar {
    background-image: url(../assets/servicio-bienestar2.gif);
  }

  .mt-3 {
    margin-top: 15% !important;
  }
}
</style>