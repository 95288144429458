<template>
  <div class="container-fluid">
    <div class="row text-center fondo-footer">
      <div class="row justify-content-center align-items-end mt-2">
        <div class="col-2 col-md-2">
        </div>
        <div class="col-5 col-md-5 mb-md-3">
          <h5>Ayuda</h5>
        </div>
        <div class="col-5 col-md-5 mb-md-3">
          <h5>Siguenos</h5>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-2 col-md-2">
          <img src="../assets/logo-mini.png" style="margin-left:50%">
        </div>
        <div class="col-5 col-md-5">
          <router-link to="/Contacto">
            <a class="footer-link">Contacto</a>
          </router-link><br />
          <!-- <a class="footer-link" data-bs-toggle="modal" data-bs-target="#preguntasModal">Pregutnas frecuentes</a><br /> -->
          <a class="footer-link" data-bs-toggle="modal" data-bs-target="#politicaModal">Política de privacidad</a><br />
          <a class="footer-link" data-bs-toggle="modal" data-bs-target="#terminosModal">Términos y condiciones</a>
        </div>
        <div class="col-5 col-md-5">
          <span>
            <a target="_blank" href="https://www.facebook.com/digisolutionpro">
              <img class="redes me-md-5" src="../assets/facebookr.png" alt="Facebook" />
            </a>
            <a target="_blank" href="https://www.instagram.com/digisolutionpro/">
              <img class="redes m-md-4" src="../assets/instagramr.png" alt="Instagram" />
            </a>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=573015315404&text=Informacion">
              <img class="redes ms-md-5" src="../assets/whatsappr.png" alt="WhatsApp" />
            </a>
          </span>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <p class="my-auto py-2">
            © {{ año }} Todos los derechos reservados • Desarrollado con ♥ por
            <a target="_blank" href="https://www.digisolutionpro.com/"> Digital Solution</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      año: new Date().getFullYear()
    }
  }
};
</script>

<style scoped>
.fondo-footer {
  background: linear-gradient(45deg, #2da0ff, #0066ff, #050096);
  background-size: 200%;
  animation: gradient-blue 2s ease infinite;
  height: 30vh;
}

@keyframes gradient-blue {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.fondo-footer h5 {
  font-size: 18px;
}

.fondo-footer a {
  color: white;
  font-size: 15px;
}

.fondo-footer p {
  color: white;
}

.footer-link,
h5 {
  padding: 1% 0;
  text-transform: uppercase;
  color: white;
}

.footer-link:hover,
p a:hover {
  color: #55ddff
}

.redes {
  margin-top: -5%;
  animation: floatz 2s infinite;
  width: 10%;
}

.redes:hover {
  filter: brightness(0.7) drop-shadow(-4px 3px 0px gray);
}

@keyframes floatz {
  0% {
    transform: rotateZ(30deg)
  }

  50% {
    transform: rotateZ(-30deg)
  }

  100% {
    transform: rotateZ(30deg)
  }
}

.col-2 img {
  margin-top: -12%;
  width: 60%;
  animation: floaty 3s infinite;
}

@keyframes floaty {
  0% {
    transform: rotateY(0deg)
  }

  50% {
    transform: rotateY(180deg)
  }

  100% {
    transform: rotateY(0deg)
  }
}

@media only screen and (max-width: 990px) {
  .fondo-footer {
    background-size: cover;
    background-position: right;
    height: auto;
  }

  .fondo-footer h5 {
    margin-bottom: 8%;
  }

  .fondo-footer a {
    font-size: 12px;
  }

  .redes {
    margin: 8%;
    width: 25%;
  }

  .my-auto a {
    font-size: 15px;
  }

  .col-2 img {
    margin-top: 100%;
    margin-left: 10% !important;
    width: 160%;
  }
}
</style>