<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">LANDING PAGE</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft" src="../assets/landing-page1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/landing-page2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/landing-page3.gif" style="width: 50%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">Desarrollamos sitios web pequeños para que expongas tu idea de
              negocio de una manera práctica pero profesional. Generalmente este tipo de páginas web tienen un diseño
              más sencillo con pocos enlaces e información básica sobre el producto, oferta o servicio además de un
              formulario para realizar el contacto con los clientes. El precio puede variar dependiendo de la tencología que se
              requiera, ofrecemos desde sitios autoadministrables desarrollados por medio de CMS (Content Management
              System) hasta aplicaciones web modernas desarrolladas con frameworks tipo SPA (Single-Page
              Application). También integramos servicios de terceros por medio de plugins o API's para que disfrutes de
              todas sus funcionalidades y beneficios. Nuestras landing pages incluyen: </h3>
            <div class="row">
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Barra de anuncios o descuentos. <br>
                  <i class="fa fa-check color"></i> Correos corporativos. <br>
                  <i class="fa fa-check color"></i> Diseño totalmente personalizado. <br>
                  <i class="fa fa-check color"></i> Formulario de contacto. <br>
                  <i class="fa fa-check color"></i> Galería de fotos. <br>
                  <i class="fa fa-check color"></i> Vinculación de redes sociales. <br>
                  <i class="fa fa-check color"></i> Versión movil y de escritorio.
                </h3>
              </div>
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Botón de WhatsApp y llamada. <br>
                  <i class="fa fa-check color"></i> Integración con Google Analytics. <br>
                  <i class="fa fa-check color"></i> Vinculación de videos. <br>
                  <i class="fa fa-check color"></i> Registro de dominio en buscadores. <br>
                  <i class="fa fa-check color"></i> Hosting y dominio por 1 año incluido. <br>
                  <i class="fa fa-check color"></i> Certificado SSL (Secure Sockets Layer). <br>
                  <i class="fa fa-check color"></i> Capacitación de la landing page.
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $1'500.000 <span class="descuento">$2'000.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "LandingPage",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

a {
  width: auto;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 100%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 100%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 250%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    width: 70% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}
</style>