<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <video src="../assets/diseno.mp4" type="video" autoplay loop muted></video>
      <div class="banner text-center">
        <div class="sticky-top">
          <h1>DISEÑO GRÁFICO</h1>
          <h3>Diseñamos la imagen corporativa de tu empresa o emprendimiento de manera profesional. Realizamos diseños creativos y modernos ajustados a tu idea de negocio</h3>
          <a href="#Go" class="btn">Vamos</a>
        </div>
      </div>
    </div>
    <!-- Que es Programación -->
    <div id="Go" class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">¿Que es Diseño Gráfico?</h1>
        </div>
      </div>
      <div class="row servicio align-items-center ">
        <div class="col-12 col-md-6 fondo">
          <h4 class="mt-3">El diseño gráfico es una disciplina que se encarga de combinar texto e imágenes con el fin de
            transmitir un mensaje visual. Se emplea, sobre todo, en el marketing y la comunicación corporativa: en el
            diseño de páginas web, en el packaging de un producto, el diseño de una cartelerías comerciales, etc</h4>
        </div>
        <div class="col-12 col-md-6" style="margin: 0; padding: 0; height: 356px;">
          <iframe width="100%" height="100%" allowfullscreen src="https://www.youtube.com/embed/quCuVZd4sFY">
          </iframe>
        </div>
      </div>
    </div>
    <!-- Servicios de Diseño -->
    <div class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">Servicios de Diseño</h1>
        </div>
      </div>
      <div class="row servicio align-items-center">
        <div class="col-12 col-md-6 fondo">
          <h2>Logo Profesional</h2>
          <h4>Creamos el logotipo perfecto para tu empresa o emprendimiento con diseños modernos de una manera
            profesional</h4>
          <router-link to="/LogoProfesional">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner logo-profesional">
        </div>
        <div class="col-12 col-md-6 banner rediseño-logo desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Rediseño de Logo</h2>
          <h4>Mejoramos o cambiamos el diseño de tu logotipo con ideas creativas para darle un mejor aspecto e impacto
            visual</h4>
          <router-link to="/RedisenoLogo">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner rediseño-logo movil">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Logo Estándar</h2>
          <h4>Diseñamos un logotipo estándar de calidad para tu empresa o emprendimiento con buenos diseños y aspecto
            visual</h4>
          <router-link to="/LogoEstandar">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner logo-estandar">
        </div>
        <div class="col-12 col-md-6 banner diseño-web desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Diseño de Interfaces</h2>
          <h4>Diseñamos el aspecto visual de tu futuro sitio web con interfaces UX/UI modernas y adaptativas a múltiples
            dispositivos</h4>
          <router-link to="/DisenoInterfaces">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner diseño-web movil">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Diseno",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  height: 82vh
}

video {
  width: 100%;
  height: 82vh;
  position: absolute;
  object-fit: fill;
}

.banner h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 80px;
  padding-top: 7%;
  animation: zoomFont 1s alternate infinite;
}

.banner h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 35px;
  margin: 3% auto;
  width: 70%;
}

.banner a {
  width: 10%;
  margin-top: 2%;
}

.gradient-orange {
  background: linear-gradient(45deg, #df7000, #ff8913, #ffa74f);
  background-size: 200%;
  animation: gradient 2s ease infinite;
  height: 20vh;
}

.banner {
  height: 50vh;
}

.servicio h2 {
  font-size: 50px;
  padding-top: 3%;
}

.servicio .fondo,
.servicio .banner {
  background-image: url(../assets/servicio-fondo.png);
  background-size: 100% 100%;
  height: 50vh;
  padding: 2%;
}

.servicio .fondo {
  animation: service 1s infinite;
}

.servicio .logo-profesional {
  background-image: url(../assets/servicio-logo-profesional.gif);
  animation: zoom 3s infinite;
}

.servicio .rediseño-logo {
  background-image: url(../assets/servicio-logo-rediseno.gif);
  animation: zoom 3.2s infinite;
}

.servicio .logo-estandar {
  background-image: url(../assets/servicio-logo-estandar.gif);

  animation: zoom 3.4s infinite;
}

.servicio .diseño-web {
  background-image: url(../assets/servicio-diseno-web.gif);
  animation: zoom 3.6s infinite;
}

@media only screen and (max-width: 990px) {
  #wrapper {
    align-items: center;
    display: flex;
  }

  video {
    object-fit: cover;
  }

  .banner h1 {
    font-size: 50px;
    padding: 0;
    margin-top: -10%;
  }

  .banner h3 {
    font-size: 23px;
    margin: 10% auto;
    width: 90%;
  }

  .banner a {
    display: inline;
  }

  .flipX {
    font-size: 38px !important;
  }

  .servicio h2 {
    font-size: 40px !important;
    line-height: 1;
    padding-top: 8%;
  }

  .servicio .diseño-web {
    background-image: url(../assets/servicio-diseno-web2.gif);
  }

  .mt-3 {
    margin-top: 15% !important;
  }
}
</style>