import { createRouter, createWebHistory } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import Diseno from '../views/Diseno.vue'
import Programacion from '../views/Programacion.vue'
import Electrica from '../views/Electrica.vue'
import Contacto from '../views/Contacto.vue'
import LogoProfesional from '../views/LogoProfesional.vue'
import RedisenoLogo from '../views/RedisenoLogo.vue'
import LogoEstandar from '../views/LogoEstandar.vue'
import DisenoInterfaces from '../views/DisenoInterfaces.vue'
import DisenoMovil from '../views/DisenoMovil.vue'
import SitioWeb from '../views/SitioWeb.vue'
import LandingPage from '../views/LandingPage.vue'
import AplicacionMovil from '../views/AplicacionMovil.vue'
import IntegracionApi from '../views/IntegracionApi.vue'
import CodigoQr from '../views/CodigoQr.vue'
import Paneles from '../views/Paneles.vue'
import Subestaciones from '../views/Subestaciones.vue'
import Belleza from '../views/Belleza.vue'
import Estudios from '../views/Estudios.vue'
import Transmision from '../views/Transmision.vue'
import Compensacion from '../views/Compensacion.vue'


const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/Diseno',
    name: 'Diseno',
    component: Diseno
  },
  {
    path: '/Programacion',
    name: 'Programacion',
    component: Programacion
  },
  {
    path: '/Electrica',
    name: 'Electrica',
    component: Electrica
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/LogoProfesional',
    name: 'LogoProfesional',
    component: LogoProfesional
  },
  {
    path: '/RedisenoLogo',
    name: 'RedisenoLogo',
    component: RedisenoLogo
  },
  {
    path: '/LogoEstandar',
    name: 'LogoEstandar',
    component: LogoEstandar
  },
  {
    path: '/DisenoInterfaces',
    name: 'DisenoInterfaces',
    component: DisenoInterfaces
  },
  {
    path: '/DisenoMovil',
    name: 'DisenoMovil',
    component: DisenoMovil
  },
  {
    path: '/SitioWeb',
    name: 'SitioWeb',
    component: SitioWeb
  },
  {
    path: '/LandingPage',
    name: 'LandingPage',
    component: LandingPage
  },
  {
    path: '/AplicacionMovil',
    name: 'AplicacionMovil',
    component: AplicacionMovil
  },
  {
    path: '/IntegracionApi',
    name: 'IntegracionApi',
    component: IntegracionApi
  },
  {
    path: '/CodigoQr',
    name: 'CodigoQr',
    component: CodigoQr
  },
  {
    path: '/Paneles',
    name: 'Paneles',
    component: Paneles
  },
  {
    path: '/Subestaciones',
    name: 'Subestaciones',
    component: Subestaciones
  },
  {
    path: '/Belleza',
    name: 'Belleza',
    component: Belleza
  },
  {
    path: '/Estudios',
    name: 'Estudios',
    component: Estudios
  },
  {
    path: '/Transmision',
    name: 'Transmision',
    component: Transmision
  },
  {
    path: '/Compensacion',
    name: 'Compensacion',
    component: Compensacion
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router