<template>
  <Modales />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import Modales from "./components/Modales.vue";
export default {
  components: {
    Modales
  }
}
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

@font-face {
  font-family: "Century Schoolbook";
  src: url("fonts/Century Schoolbook.ttf");
}

@font-face {
  font-family: "Code 8x8";
  src: url("fonts/Code 8x8.ttf");
}

@font-face {
  font-family: "Cooper Black";
  src: url("fonts/Cooper Black.ttf");
}

@font-face {
  font-family: "Nightmare Codehack";
  src: url("fonts/Nightmare Codehack.otf");
}

.flipX {
  animation: 2.5s flipX ease infinite;
}

@keyframes flipX {
  0% {
    opacity: 0.3;
    transform: rotateX(90deg);
  }

  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }

  100% {
    transform: rotateX(720deg);
  }
}

@keyframes float {
  0% {
    width: 12%;
  }

  50% {
    width: 15%;
  }

  100% {
    width: 12%;
  }
}

@keyframes floatLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-15%);
  }
}

@keyframes floatRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(15%);
  }
}

@keyframes floatUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-15%);
  }
}

@keyframes floatZ {
  0% {
    transform: rotateZ(30deg)
  }

  50% {
    transform: rotateZ(-30deg)
  }

  100% {
    transform: rotateZ(30deg)
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.popOutIn {
  animation: 2s popOutIn ease infinite;
}

@keyframes popOutIn {
  0% {
    color: gray;
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  25% {
    transform: scale(1.5);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }

  50% {
    color: white;
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}

.boxOutIn {
  animation: 1s boxOutIn alternate infinite;
}

@keyframes boxOutIn {
  0% {
    transform: scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes service {
  0% {
    background-image: url(./assets/servicio-fondo.png);
  }

  50% {
    background-image: url(./assets/servicio-fondo2.png);
  }

  100% {
    background-image: url(./assets/servicio-fondo.png);
  }
}

@keyframes spin {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(-360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateZ(30deg)
  }

  50% {
    transform: rotateZ(-30deg)
  }

  100% {
    transform: rotateZ(30deg)
  }
}

@keyframes zoom {
  0% {
    background-size: 100%;
    background-position: left;
    filter: brightness(0.9);
  }

  50% {
    background-size: 110%;
    background-position: center;
    filter: brightness(1.1);
  }

  100% {
    background-size: 100%;
    background-position: right;
    filter: brightness(0.9);
  }
}

@keyframes zoomFont {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

html {
  scroll-behavior: smooth;
}

a {
  color: #ff9946;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
}

a:hover {
  color: #945c02;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.btn {
  font-family: "Cooper Black";
  text-transform: uppercase;
  font-size: 30px;
  border-radius: 20px;
  color: white;
  background-color: #df7000;
  text-shadow: -3px -1px 0px black;
  box-shadow: -4px 4px rgba(0, 0, 0, 0.8);
}

.btn:hover {
  color: black;
  background-color: #c06000;
  text-shadow: -3px -1px 0px rgb(255, 255, 255);
  box-shadow: -4px 4px rgba(255, 255, 255, 0.8);
}

.btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.btn:focus,
.btn-close:focus,
input,
textarea {
  box-shadow: none !important;
}

.descuento {
  color: #e0e0e0 !important;
  text-decoration: line-through;
  vertical-align: top;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
}

.flechad {
  /* background-image: url("assets/flechad.png"); */
  width: 50px;
  height: 50px;
}

.flechai {
  /* background-image: url("assets/flechai.png"); */
  width: 50px;
  height: 50px;
}

form label {
  text-transform: uppercase;
  margin-top: 2%;
  font-weight: bold;
}

form input,
form select {
  text-align: center;
  border: none;
  border-radius: 20px !important;
  width: 100%;
  height: 35px !important;
}

form input:focus,
form select:focus {
  outline: none;
}

form p a {
  color: #774700 !important;
}

form p a:hover {
  color: #cc7400 !important;
}

h1 {
  color: white;
  font-family: "Cooper Black";
  text-transform: uppercase;
  font-size: 50px;
}

h2 {
  color: white;
  font-family: "Cooper Black";
  font-size: 30px;
}

h3 {
  color: white;
  font-size: 40px;
}

h4 {
  color: white;
  font-size: 30px;
}

.menu {
  padding: 1% 0;
  /* background-image: url("assets/nav-fondo.png"); */
  background-attachment: fixed;
}

.modal {
  overflow: auto !important;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal-content {
  background-color: #ffd1a4;
  border-radius: 40px;
  padding: 3.5% 8%;
  animation: 1s colorf alternate infinite;
}

@keyframes colorf {
  0% {
    background-color: #ffd1a4;
  }

  50% {
    background-color: #fcc085;
  }

  100% {
    background-color: #ffd1a4;
  }
}

.modal-content h2 {
  color: white;
  font-size: 100px;
  text-shadow: -5px 0px 0px black, -8px 0px 0px #9e6201;
  margin: -2% 0;
}

.movil {
  display: none;
}

.price span {
  padding: 0;
  color: #ff9f1a;
}

.redes {
  filter: drop-shadow(-4px 3px 0px black) drop-shadow(-3px 3px 0px #050096);
}

.redes:hover {
  transform: rotate(-20deg);
  transition: all 0.3s ease 0s;
}

.requerido {
  vertical-align: top;
  color: red;
  font-size: 10px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky a {
  font-family: "Century Schoolbook";
  font-weight: bold;
  font-size: 20px;
  color: black;
  text-transform: uppercase;
}

.sticky a:hover {
  color: white;
}

@media only screen and (max-width: 990px) {
  .card-body h1 {
    font-size: 50px !important;
  }

  .flechad {
    width: 30px;
    height: 30px;
  }

  .flechai {
    width: 30px;
    height: 30px;
  }

  .flechad-roja {
    width: 30px;
    height: 30px;
  }

  .flechai-roja {
    width: 30px;
    height: 30px;
  }

  .desktop {
    display: none !important;
  }

  .movil {
    display: block;
    padding: 0;
  }

  .sticky a {
    font-size: 12px;
  }

  h1 {
    text-align: center;
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3,
  h4 {
    font-size: 20px;
  }
}
</style>
