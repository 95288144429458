<template>
  <!-- Modal Inicio -->
  <div class="modal fade" id="inicioModal" tabindex='-1'>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center">
        <form class="contact-form" @submit.prevent="sendEmailModal">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <img class="pb-3" src="../assets/logo.png" />
          <p>No te pierdas nuestras últimas novedades. Regístrate aquí para
            recibir noticias de interés y ofertas especiales.</p>
          <div class="form-group">
            <label>Nombre:<span class="requerido">*</span></label>
            <input type="text" class="form-control" name="from_name" required />
          </div>
          <div class="form-group">
            <label>Número celular:<span class="requerido">*</span></label>
            <input type="tel" class="form-control" name="from_number" minlength="7" required />
          </div>
          <div class="form-group">
            <label>Correo electrónico:</label>
            <input type="email" class="form-control" name="from_email" />
          </div>
          <div class="form-group">
            <label>Temas de interés:<span class="requerido">*</span></label>
            <br />
            <select name="from_select" required>
              <option value="" hidden></option>
              <option value="Programación">Programación</option>
              <option value="Diseño Gráfico">Diseño Gráfico</option>
              <option value="Ingeniería Eléctrica">Ingeniería Eléctrica</option>
            </select>
          </div>
          <br>
          <p>
            Al enviar tus datos aceptas nuestra
            <a data-bs-toggle="modal" data-bs-target="#politicaModal">política de privacidad</a>.
          </p>
          <button class="btn mb-2" type="submit">Registrarse</button>
        </form>
      </div>
    </div>
  </div>
  <!-- Modal Servicio -->
  <div class="modal fade" id="servicioModal" tabindex='-1'>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content text-center">
        <form class="contact-form" @submit.prevent="sendEmailService">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <h2 class="pb-4 pt-3">Solicitar Servicio</h2>
          <p>Para nosotros es un gusto atenderte. Déjanos tus datos y el servicio de interés para ponernos en contacto.
          </p>
          <div class="form-group">
            <label>Nombre:<span class="requerido">*</span></label>
            <input type="text" class="form-control" name="from_name" required />
          </div>
          <div class="form-group">
            <label>Número celular:<span class="requerido">*</span></label>
            <input type="tel" class="form-control" name="from_number" minlength="7" required />
          </div>
          <div class="form-group">
            <label>Correo electrónico:</label>
            <input type="email" class="form-control" name="from_email" />
          </div>
          <div class="form-group">
            <label>Servicio de interés:<span class="requerido">*</span></label>
            <br />
            <select name="from_select" required>
              <option value="" hidden></option>
              <option value="Sitio Web eCommerce">Sitio Web</option>
              <option value="Landing Page">Landing Page</option>
              <option value="Aplicación Móvil">Aplicación Móvil</option>
              <option value="Código QR">Código QR</option>
              <option value="Logo Profesional">Logo Profesional</option>
              <option value="Rediseño de Logo">Rediseño de Logo</option>
              <option value="Logo Estándar">Logo Estándar</option>
              <option value="Diseño de Interfaces">Diseño de Interfaces</option>
              <option value="Estudios Eléctricos">Estudios Eléctricos</option>
              <option value="Paneles Solares">Paneles Solares</option>
              <option value="Líneas de Transmisión">Líneas de Transmisión</option>
              <option value="Subestaciones">Subestaciones</option>
            </select>
          </div>
          <br>
          <p>Al enviar tus datos aceptas nuestra <a data-bs-toggle="modal" data-bs-target="#politicaModal">política de
              privacidad</a>.
          </p>
          <button class="btn mb-2" type="submit">Enviar</button>
        </form>
      </div>
    </div>
  </div>
  <!-- Modal Política de Privacidad -->
  <div class="modal fade" id="politicaModal" tabindex='-1'>
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content text-center">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <h2 class="pb-5 pt-3">Política de Privacidad</h2>
        <p style="text-align: justify">
          La presente Política de Privacidad establece los términos en que usa y protege la información que es
          proporcionada por sus usuarios al momento de utilizar este sitio web. Esta compañía está comprometida con la
          seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la
          cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de
          este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo
          que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con
          dichos cambios.<br><br>

          Información que es recogida:<br>
          Nuestro sitio web podrá recoger información personal por ejemplo: Nombre, información de contacto como su
          dirección de correo electrónica e información demográfica. Así mismo cuando sea necesario podrá ser requerida
          información específica para procesar algún pedido o realizar una entrega o facturación.<br><br>

          Uso de la información recogida:<br>
          Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible,
          particularmente para mantener un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros
          productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro
          sitio con ofertas especiales, nuevos productos y otra información publicitaria que consideremos relevante para
          usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados a la dirección que
          usted proporcione y podrán ser cancelados en cualquier momento.<br>
          Estámos altamente comprometidos para cumplir con el compromiso de mantener su información segura. Usamos los
          sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no
          autorizado.<br><br>

          Cookies:<br>
          Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su
          ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al
          tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies
          es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio persona-
          lizado de su web.<br>
          Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia.
          Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma
          permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies
          ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador
          ni de usted, a menos de que usted así lo quiera y la proporcione directamente . Usted puede aceptar o negar
          el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener
          un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies.
          Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.<br><br>

          Enlaces a Terceros:<br>
          Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de
          clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y
          por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros
          sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomen-
          dable que los consulte para confirmar que usted está de acuerdo con estas.<br><br>

          Control de su información personal:<br>
          En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es
          proporcionada a nuestro sitio web. Cada vez que se le solicite rellenar un formulario, como el de alta de
          usuario, puede marcar o desmarcar la opción de recibir información por correo electrónico. En caso de que haya
          marcado la opción de recibir nuestro boletín o publicidad usted puede cancelarla en cualquier momento.
          Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su
          consentimiento, salvo que sea requerido por un juez con un orden judicial. Se reserva el derecho de cambiar
          los términos de la presente Política de Privacidad en cualquier momento.
        </p>
      </div>
    </div>
  </div>
  <!-- Modal Términos y Condiciones -->
  <div class="modal fade" id="terminosModal" tabindex='-1'>
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content text-center">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <h2 class="pb-5 pt-3">Términos y Condiciones</h2>
        <p style="text-align: justify">
          PRIMERO: POLÍTICA DE COOKIES.<br>
          El usuario de la página web www.digisolutionpro.com, de las plataformas de compras on-line, cursos
          virtuales y demás servicios digitales de DIGITAL SOLUTION, acepta el uso de cookies, que se definen a
          continuación:<br>
          “Una cookie es un pequeño fragmento de texto que los sitios web que visita envían al navegador y que permite
          que el sitio web recuerde información sobre su visita, como su idioma preferido y otras opciones, con el fin
          de facilitar su próxima visita y hacer que el sitio le resulte más útil. Las cookies desempeñan un papel muy
          importante y contribuyen a tener una mejor experiencia de navegación para el usuario.”
          Finalidad de las cookies:
          - Identificar las preferencias de navegación de los usuarios.
          - Para efectos promocionales, comerciales y publicitarios.
          - Para efectos estadísticos, entre otros fines.<br><br>

          SEGUNDO: PROPIEDAD INTELECTUAL DE DIGITAL SOLUTION.<br>
          Todas las creaciones que encuentran en el interfaz de la página web o los cursos o capacitaciones virtuales
          que identifican a DIGITAL SOLUTION como: logos, slogan, nombre comercial, textos, enseñas, gráficos, vídeos,
          audios, imágenes, entre otros contenidos relacionados con la empresa son propiedad de DIGITAL SOLUTION, en
          consecuencia, están reservados todos los derechos de autor a favor de esta empresa quien es el titular
          exclusivo de los derechos de propiedad intelectual sobre todos los contenidos publicados en la página
          www.digisolutionpro.com y plataformas aliadas que facilitan la ejecución de los cursos o
          capacitaciones y la comercialización de sus productos y servicios.<br>
          Por lo anterior, está prohibido utilizar logos, slogan, nombre comercial, textos, enseñas, gráficos, vídeos,
          audios, imágenes, entre otros contenidos relacionados con la empresa que identifican comercialmente los
          productos y servicios que oferta DIGITAL SOLUTION, sin la debida autorización previa y expresa por parte de la
          empresa.<br><br>

          TERCERO: DERECHOS RESERVADO SOBRE LOS CONTENIDOS PUBLICADOS.<br>
          Todos los contenidos (textos, clases streaming, vídeos, audios, imágenes, etc) que sean compartidos con los
          participantes y usuario de la página web www.digisolutionpro.com, cursos o capacitaciones virtuales
          son propiedad de DIGITAL SOLUTION, en consecuencia están reservados todos los derechos de autor a favor de
          esta empresa quien es el titular exclusivo de los derechos de propiedad intelectual sobre los contenidos. Por
          lo anterior, el usuario o participante acepta y se compromete a lo siguiente:<br>
          1. No realizar la reproducción total o parcial, su traducción, inclusión, transmisión, almacenamiento o acceso
          a través de medios analógicos, digitales o de cualquier otro sistema o tecnología creada o por crearse, sin
          autorización previa y escrita propiedad de DIGITAL SOLUTION.<br>
          2. No descargar o reproducir sin autorización las imágenes, textos y demás contenido publicado en la Página
          Web, landing pages, cursos y talleres virtuales.<br>
          3. No suprimir, eludir, o manipular las medidas de protección técnica de los contenidos publicados y
          protegidos por copyright (derechos de autor).<br>
          4. No realizar actividades técnicas que impidan el libre uso, administración y publicación de los contenidos
          por parte de los administradores debidamente autorizados por DIGITAL SOLUTION.<br>
          No obstante, la información podrá ser utilizada de acuerdo con las limitaciones previstas en la legislación
          actual sobre la materia y en particular, según lo previsto en el Artículo 31 de la Ley 23 de 1982 de derechos
          de autor.<br><br>

          CUARTO: PROPIEDAD INTELECTUAL DE LOS CONTENIDOS DE LOS USUARIOS O PARTICIPANTES.<br>
          Con la aceptación de estos términos, los participantes (usuarios, clientes y estudiantes) manifiestan bajo la
          gravedad del juramento que los contenidos que entregarán en la ejecución de los cursos, talleres, asesorías, y
          capacitaciones son originales, son de su autoría, ostentan la titularidad o autorización de uso de sus
          titulares o autores y que no contienen utilizaciones de otras obras, fuera de los límites autorizados por la
          ley o en contravención a derechos de terceros.<br>
          Con la aceptación de estos términos se autoriza de manera previa, expresa e inequívoca a DIGITAL SOLUTION a
          realizar la reproducción, comunicación al público, edición y transformación de los contenidos entregados, con
          la finalidad de que puedan realizar evaluaciones, retroalimentación, actividades en el marco de los cursos y
          capacitaciones y materiales digitales e impresos, demostrando ejemplos de casos de éxito sobre los contenidos
          entregados que podrán tener una comunicación al público con fines comerciales o didácticos.<br><br>

          QUINTO: PREFERENCIA DE LEY APLICABLE Y RESOLUCIÓN DE CONTROVERSIAS.<br>
          Las condiciones de uso de la página Web, plataforma de educación virtual y demás productos y servicios de
          DIGITAL SOLUTION se rigen por las leyes de la República de Colombia. En esa medida, para cualquier efecto
          legal o judicial, el lugar de las presentes condiciones es la ciudad de Bogotá, República de Colombia, y
          cualquier controversia que surja de su interpretación o aplicación se someterá a los jueces de la República de
          Colombia, previo el agotamiento de los mecanismos alternativos de resolución de conflictos.<br><br>

          SEXTO: ACEPTACIÓN DE TÉRMINOS Y CONDICIONES.<br>
          El usuario de la página web www.digisolutionpro.com, de las plataformas de compras on-line, cursos
          virtuales y demás servicios digitales de DIGITAL SOLUTION, aceptan los términos y condiciones establecidas en
          el presente documento.<br><br>

          SÉPTIMO: TÉRMINOS Y CONDICIONES SOBRE LOS CURSOS.<br>
          7.1 DISPONIBILIDAD DE CURSOS.<br>
          DIGITAL SOLUTION se compromete a mantener su página web actualizada con la finalidad de que los cursos,
          talleres, asesorías y capacitaciones virtuales que se oferten estén disponibles en tiempo real para su
          adquisición o inscripción, en consecuencia, el usuario de la página web entiende y acepta que, si solicita
          información de un servicio ofrecido en dicha página web o realiza la inscripción, ésta se encuentra disponible
          para iniciar su ejecución.<br>
          7.2. TIPOS DE CURSOS VIRTUALES.<br>
          DIGITAL SOLUTION ofrece diferentes cursos virtuales: a. Curso virtual gratuito. Son cursos que están
          disponibles de manera permanente para su inscripción, no tienen un valor económico para acceder a ellos, por
          lo general estos cursos no incluyen certificación ni materiales de apoyo. b. Curso virtual oneroso o pago. Son
          cursos cuya inscripción tiene un valor económico, por lo general incluyen certificación por su aprobación y
          materiales de apoyo.<br>
          7.3. INSCRIPCIÓN.<br>
          La inscripción se formaliza con el diligenciamiento de la información personal solicitada para tal fin y con
          el pago en su totalidad de todos los valores indicados para poder participar. El pago se realizará a través de
          la pasarela de pagos de la página web o por medio de la plataforma de Hotmart, en consecuencia, el usuario
          deberá conocer y aceptar las políticas de datos personales y demás términos y condiciones de las referidas
          pasarelas de pago.<br>
          7.4. POLÍTICA DE PAGO, RETRACTO Y DEVOLUCIÓN.<br>
          Es de pleno conocimiento y aceptación por el usuario que el precio pagado por el curso comprende el valor del
          curso expresado en pesos colombianos o un valor aproximado en dólares americanos, las cargas tributarias
          propias de la operación de venta, el cual se reflejará en una factura o comprobante de pasarela de pago, así
          como cualquier obligación legal aplicable, de acuerdo con las normas colombianas. En concordancia con la Ley
          1480 de 2011, el participante podrá ejercer el derecho de retracto, el cual será aplicable siempre y cuando el
          Curso o Capacitación con cinco (5) días calendario anteriores al inicio de su ejecución tiempo que tendrá para
          declinar su intención de realizar el curso al cual se inscribió, por lo tanto, tendrá la potestad de solicitar
          la devolución de su dinero.<br>
          7.5. PARTICIPANTES.<br>
          Se consideran participantes a las personas que culminan el proceso de inscripción del curso, taller o asesoría
          y realizan los pagos correspondientes. Los participantes deberán ser personas naturales mayores de edad.<br>
          7.6. INSTRUCTORES.<br>
          Se consideran instructores a las personas plenamente capacitados para el ejercicio impartir los cursos,
          talleres, asesorías o capacitaciones ofertadas por DIGITAL SOLUTION, quienes deben tener una vinculación,
          laboral o comercial con DIGITAL SOLUTION. DIGITAL SOLUTION se compromete a publicar el perfil profesional y
          laboral de los instructores en su página web o dentro de la información de cada curso, taller, asesoría o
          capacitación para que los participantes estén informados.<br>
          7.7. METODOLOGÍA DE LOS CURSOS.<br>
          Las metodologías y técnicas de capacitación y enseñanza utilizadas o aplicadas en la ejecución de los curos
          corresponden al Know How de DIGITAL SOLUTION que se encuentra protegido por secreto comercial, por tanto, no
          serán objeto de divulgación ni transferencia en la ejecución de los cursos. En consecuencia, el participante
          se compromete a guardar la debida confidencialidad de las metodologías aplicadas por los instructores en los
          cursos y capacitaciones.<br>
          7.8. DURACIÓN DEL CURSO.<br>
          Cada curso estará disponible para su acceso por un tiempo determinado desde su inscripción. El tiempo de
          acceso varía según el número de módulos o características del curso. En la descripción de cada curso se
          indicará el tiempo de acceso que posee el curso. 7.9. MATERIAL Y ACOMPAÑAMIENTO DE LOS CURSOS Según el tipo la
          temática y complejidad de cada curso incluirá o no material de apoyo que será entregado al participante, así
          mismo los cursos podrán incluir o no un tiempo de acompañamiento por parte del capacitador, en la descripción
          del curso se indicará si incluye material y acompañamiento la forma en que se entregará este material o se
          efectuará el acompañamiento.<br>
          7.10. CERTIFICACIÓN.<br>
          Algunos cursos están sujetos a la certificación por su aprobación. DIGITAL SOLUTION, publicará con la
          información del curso si este incluye o no Certificación. En los cursos que ofrecen certificado, habrá algunos
          certificados que sean por participación, otros por aprobación, y otros certificarán a líderes y facilitadores
          en las diferentes categorías ofrecidas en los servicios “Para liderar”. En los casos que se otorga certificado
          por aprobación, se entenderá aprobado el curso cuando la persona ha finalizado el 100% de los módulos.
          7.11. ENVÍO DE CERTIFICADO.<br>
          Los certificados se emitirán en formato digital, con firmas digitalizadas, y serán enviados a los
          participantes mediante el correo electrónico aportado en el momento de la inscripción.<br><br>

          OCTAVO: TÉRMINOS Y CONDICIONES SOBRE LA COMPRA DE PRODUCTOS Y SERVICIOS.<br>
          - Adquisición de servicios: Se pueden adquirir servicios como asesorías personalizadas, mediante la pasarela
          de pago de la página www.digisolutionpro.com, Una vez que se haya realizado el pedido y el pago
          correspondiente se agendará la asesoría de común acuerdo entre el usuario y DIGITAL SOLUTION por vía
          telefónica, WhatsApp o por vía e-mail; así mismo, DIGITAL SOLUTION notificará al usuario la fecha y hora
          acordada vía e-mail y se llevarán a cabo de manera virtual por medio de video llamada, o de manera presencial
          según acuerdo mutuo.<br>
          - Participar en “PROYECTOS SOCIALES”: los usuarios interesados en participar “PROYECTOS SOCIALES” de HUMANIZAR
          GLOBAL, deberán diligenciar un formulario en la página: www.digisolutionpro.com, en la que incluirá
          información de su organización con el fin de establecer comunicación personalizada y generar relaciones
          comerciales o de alianzas.<br>
          - Adquisición de material didáctico y literario: Mediante la página www.digisolutionpro.com, los
          usuarios podrán adquirir productos como material didáctico y literario, el cual se puede comprar por la
          pasarela de pagos, el cual hará la notificación de la compra por e-mail y se envía a cualquier destino
          nacional por medio de la empresa SERVIENTREGA. El tiempo de entrega podrá variar entre 3 a 7 días hábiles,
          teniendo en cuenta la política de envíos, rastreo y entregas de la empresa SERVIENTREGA. Los costos de envío
          estarán cubiertos dentro del valor pagado.<br><br>

          NOVENO: DERECHO A CAMBIAR Y MODIFICAR LOS TÉRMINOS.<br>
          Los presentes términos y condiciones generales de uso, que rigen para todos los servicios y comunicaciones,
          actuales o que sean incluidos en el futuro, y abarcan a todos aquellas personas que, ya sean usuarios o
          terceros que accedan, permanezcan o hagan uso de la página Web para consulta y/o adquieran nuestros productos
          y
          servicios. DIGITAL SOLUTION se reserva el derecho a modificar en cualquier momento y sin
          necesidad de previo aviso los presentes términos y condiciones de esta página Web, los cuales entrarán en
          vigor a partir publicación. Se recomienda la visita periódica de la sección de términos y condiciones.<br><br>

          DÉCIMO: INFORMACIÓN DE CONTACTO.<br>
          Para cualquier información adicional a la expuesta en los presentes términos de referencia la información de
          contacto de DIGITAL SOLUTION es la siguiente:<br>
          Dirección: Calle 17 # 4-68 AP 310<br>
          Ciudad: Bogotá<br>
          País: Colombia<br>
          Teléfono: (+57) 301 531 5404<br>
          Correo electrónico: info@digisolutionpro.com
        </p>
      </div>
    </div>
  </div>
  <!-- Modal Preguntas Frecuentes -->
  <div class="modal fade" id="preguntasModal" tabindex='-1'>
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content text-center">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <h2 class="pb-5 pt-3">Preguntas Frecuentes</h2>
        <p style="text-align: justify">
          La aceptación del aviso de privacidad y esta autorización implica la
          aceptación expresa y plena de las condiciones generales de la
          política de tratamiento de datos personales, se entiende que
          comprende y acepta cada uno de los términos y condiciones aquí
          expresados, así mismo, se autoriza de manera previa, libre,
          informada y expresa el uso de mis datos personales a favor de
          DIGITAL SOLUTION en su condición de responsable del tratamiento de
          datos personales, con la finalidad de que pueda utilizar mis datos
          aquí entregados para que pueda tener una base de datos de sus
          usuarios, proveedores, clientes, colaboradores, estudiantes,
          capacitadores, empleados o cualquier otra persona registrada en
          nuestros portales, y pueda de manera clasificada y ordenada enviar
          información de los productos y servicios que oferta y comercializa,
          enviar información necesaria para ejecutar las compras y adquisición
          de productos y servicios, enviar promociones de los productos y
          servicios, generar las inscripciones de los participantes de los
          cursos, y clientes de los productos y servicios, enviar los
          productos que adquieran mediante compra virtual o física, entre
          otras interacciones necesarias para cumplir con los compromisos
          adquiridos con el titular de los datos personales. El titular de los
          datos personales tiene derechos a conocer, acceder, consultar,
          actualizar, rectificar o suprimir sus datos personales autorizados a
          DIGITAL SOLUTION en su calidad de responsable del tratamiento o
          encargado del tratamiento mediante solicitud al siguiente correo
          electrónico: info@digisolutionpro.com en caso de ser necesario.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: "Modales",
  methods: {
    sendEmailModal: (e) => {
      emailjs.sendForm("gmail", "template_lnlgx1t", e.target, "AsFMF6h7Sap_mRVIn").then((result) => {
        alert("Tu mensaje ha sido enviado.");
        console.log("Mensaje enviado exitosamente.", result.status, result.text);
      }, (error) => {
        alert("Error de conexión con el servidor.", error);
      });
    },
    sendEmailService: (e) => {
      emailjs.sendForm("gmail", "template_ayhekja", e.target, "AsFMF6h7Sap_mRVIn").then((result) => {
        alert("Tu mensaje ha sido enviado.");
        console.log("Mensaje enviado exitosamente.", result.status, result.text);
        window.location.reload();
      }, (error) => {
        alert("Error de conexión con el servidor.", error);
      });
    }
  },
  mounted() {
    setTimeout(() => {
      const myModal = document.getElementById('inicioModal');
      const modal = new Modal(myModal);
      modal.show();
    }, 15000)
  }
}
</script>

<style scoped>
h2 {
  font-size: 65px !important;
  line-height: 0.8 !important;
}

@media only screen and (max-width: 990px) {
  h2 {
    font-size: 50px !important;
  }

  img {
    width: 80%;
  }
}
</style>