/**
 * Mocking client-server processing
 */
const _products = [
  { id: 0, img: require("../assets/logo-mini.png"), title: "Producto", price: 1000, inventory: 10 }
  ];
  
  export default {
    getProducts(cb) {
      setTimeout(() => cb(_products), 100);
    },
    buyProducts(_products, cb, errorCb) {
      setTimeout(() => {
        // simulate random checkout failure.
        Math.random() > 0.5 || navigator.userAgent.indexOf("PhantomJS") > -1
          ? cb()
          : errorCb();
      }, 100);
    }
  };