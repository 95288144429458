<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <video src="../assets/contacto.mp4" type="video" autoplay loop muted></video>
      <div class="banner text-center">
        <div class="sticky-top">
          <h1>Contáctanos</h1>
          <h3>Estamos disponibles para resolver cualquier duda que tengas</h3>
          <a href="#Contacto" class="btn">Vamos</a>
        </div>
      </div>
    </div>
    <!-- Formulario -->
    <div id="Contacto" class="container-fluid formulario">
      <div class="row align-items-center text-center">
        <div class="col-md-6 pe-md-5">
          <div class="row">
            <div class="col-md-12 text-center">
              <div class="container">
                <form class="contact-form mt-5" @submit.prevent="sendEmail">
                  <h2>¿Que Necesitas?</h2>
                  <div class="form-group text-left">
                    <label>Nombre:<span class="requerido">*</span></label>
                    <input type="text" class="form-control" name="from_name" required />
                  </div>
                  <div class="form-group text-left">
                    <label>Número celular:<span class="requerido">*</span></label>
                    <input type="tel" class="form-control" name="from_number" minlength="7" required />
                  </div>
                  <div class="form-group text-left">
                    <label>Correo electrónico:</label>
                    <input type="email" class="form-control" name="from_email" />
                  </div>
                  <div class="form-group text-left">
                    <label>Mensaje adicional:<span class="requerido">*</span></label>
                    <textarea type="text" class="form-control" name="from_msg" minlength="10" required />
                  </div>
                  <br>
                  <p>Al enviar tus datos aceptas nuestra
                    <a data-bs-toggle="modal" data-bs-target="#politicaModal">política de privacidad</a>.
                  </p>
                  <button class="btn mx-3" type="submit">
                    Enviar mensaje
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-12 my-5">
              <img src="../assets/logo-mini.png">
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-md-4">
                  <img src="../assets/tel.png" />
                  <br>
                  <p class="d-inline">(+57) 301 531 5404</p>
                  <br>
                  <p class="d-inline">(+57) 313 498 7703</p>
                </div>
                <div class="col-12 col-md-4">
                  <img src="../assets/cal.png" />
                  <br>
                  <p class="d-inline">
                    Lunes a Viernes:
                    <br />8:00am - 6:00pm <br />Sábados: <br />8:00am - 1:00pm
                  </p>
                </div>
                <div class="col-12 col-md-4">
                  <img src="../assets/mail.png" />
                  <br>
                  <p class="d-inline">info@digisolutionpro.com</p>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="row text-center">
            <h2>Síguenos</h2>
            <span>
              <a target="_blank" href="https://www.facebook.com/digisolutionpro">
                <img class="redes" src="../assets/facebook.png" alt="Facebook" />
              </a>
              <a target="_blank" href="https://www.instagram.com/digisolutionpro/">
                <img class="redes mx-md-5" src="../assets/instagram.png" alt="Instagram" />
              </a>
              <a target="_blank" href="https://api.whatsapp.com/send?phone=573015315404&text=Informacion">
                <img class="redes" src="../assets/whatsapp.png" alt="WhatsApp" />
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Contacto",
  components: {
    Anuncios,
    Navbar,
    Footer
  },
  methods: {
    sendEmail: (e) => {
      emailjs.sendForm("gmail", "template_lnlgx1t", e.target, "AsFMF6h7Sap_mRVIn").then((result) => {
        alert("Tu mensaje ha sido enviado.");
        console.log("Mensaje enviado exitosamente.", result.status, result.text);
        window.location = "https://digisolutionpro.com/";
      }, (error) => {
        alert("Error de conexión con el servidor.", error);
      });
    }
  }
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  height: 82vh
}

video {
  width: 100%;
  height: 82vh;
  filter: brightness(80%);
  position: absolute;
  object-fit: fill;
}

.banner h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 80px;
  padding-top: 7%;
  animation: zoomFont 1s alternate infinite;
}

.banner h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 35px;
  margin: 3% auto;
  width: 40%;
}

.banner a {
  width: 10%;
  margin-top: 2%;
}

.formulario {
  height: 100vh;
  background: linear-gradient(0deg, #fabb7d, #fa932b, #df7000);
  background-attachment: fixed;
}

.formulario .container-fluid {
  padding: 0 7%;
}

form {
  background-color: #ffd1a4;
  border-radius: 40px;
  padding: 5%;
  animation: colorFondo 1s infinite;
}

@keyframes colorFondo {
  0% {
    background-color: #ffd1a4;
  }

  50% {
    background-color: #fcc085;
  }

  100% {
    background-color: #ffd1a4;
  }
}

form h2 {
  text-shadow: -5px 0px 0px black, -8px 0px 0px #9e6201 !important;

}

form h2,
.formulario h2 {
  color: white;
  font-size: 50px;
  margin-bottom: 5%;
}

textarea {
  border-radius: 20px;
  min-height: 100px;
}

.d-inline {
  color: white;
}

.my-5 img {
  animation: flipX 3s infinite;
}

@keyframes floaty {
  0% {
    transform: rotateY(0deg)
  }

  50% {
    transform: rotateY(180deg)
  }

  100% {
    transform: rotateY(0deg)
  }
}

.col-md-4 img {
  animation: floatz 3s infinite;
  filter: none
}

@keyframes floatz {
  0% {
    transform: rotateZ(30deg)
  }

  50% {
    transform: rotateZ(-30deg)
  }

  100% {
    transform: rotateZ(30deg)
  }
}

.redes {
  width: 6%;
  filter: none
}

@media only screen and (max-width: 990px) {
  #wrapper {
    align-items: center;
    display: flex;
  }

  video {
    object-fit: cover;
  }

  .banner h1 {
    font-size: 40px;
    padding: 0;
    margin: 0;
  }

  .banner h3 {
    font-size: 25px;
    margin: 10% auto;
    width: 80%;
  }

  .banner a {
    display: inline;
  }

  .container {
    padding: 0;
  }

  .formulario {
    text-align: center;
    height: 100%;
    padding: 0 8%;
    margin: 0 !important;
  }

  .formulario .col-12 {
    margin-top: 5% !important;
  }

  .formulario .info img {
    width: 20%;
    margin-right: 2%;
  }

  .formulario p {
    font-size: 15px;
  }

  .formulario h2 {
    font-size: 40px !important;
  }

  .formulario h3 {
    margin: 3% auto;
  }

  .formulario .btn {
    font-size: 18px;
  }

  a img {
    width: 20%;
    padding: 5%;
  }

  .my-5 {
    margin: 0 !important;
  }
}
</style>
