<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <div class="container">
        <div class="row pt-5">
          <div class="col-12 col-md-8 pt-4">
            <h1>INTEGRACIÓN DE API</h1>
            <h3>Si aún no sabes que es una API de integración, puede que tu empresa no esté aprovechando las
              ventajas de este tipo de herramienta.
              Las APIs se han convertido en una herramienta clave para la mayoría de las empresas. Estas les
              permiten automatizar y controlar sus procesos; realizar seguimientos y monitoreos en tiempo real,
              además, del acceso a los datos que estas proporcionan.</h3>
            <div class="row">
              <div class="col-12">
                <h3>Optar por una estrategia de integración de APIs, ahorra el tiempo en desarrollo de un sistema propio
                  que al poco tiempo puede quedar desactualizado. Es por esta razón, que muchas empresas eligen este
                  tipo de estrategia además de otras ventajas.</h3>
                <h3>Si te interesa conocer más acerca de APIs de integración y cómo tu empresa puede beneficiarse con
                  este tipo de herramientas, contáctanos para brindarte una asesoria personalizada.</h3>
              </div>
            </div>
            <br>
            <div class="row justify-content-evenly mb-5">
              <div class="col-12 col-md-6">
                <h3 class="precio">PRECIO DESDE: USD $750 <span class="descuento">$900</span></h3>
              </div>
              <div class="col-12 col-md-6">
                <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-5 float">
            <img src="../assets/integracion-api.png">
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "IntegracionApi",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  background-image: url(../assets/banner-inicio.png);
  background-attachment: fixed;
  background-size: 100% 100%;
  min-height: 82vh
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  text-align: justify;
  width: 90%;
}

a {
  width: auto;
}

.float {
  margin-top: 10% !important;
  rotate: 45deg;
  animation: float 3s ease-in-out infinite;
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin: 0 !important;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

@keyframes float {
  0% {
    transform: skew(10deg) translateY(-20px);
  }

  50% {
    transform: skew(5deg, 5deg) translateY(40px) rotateX(25deg);
  }

  100% {
    transform: skew(10deg) translateY(-20px);
  }
}

@media only screen and (max-width: 990px) {
  #wrapper {
    background-image: url(../assets/banner-inicio-movil.png);
    background-size: 100% 100%;
    height: auto;
    align-items: center;
    text-align: center;
    display: flex;
  }

  .container h1 {
    font-size: 40px;
    padding: 0;
    margin: 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .float img {
    width: 100%;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }

  @keyframes float {
    0% {
      transform: translatey(-15px);
    }

    50% {
      transform: translatey(-35px);
    }

    100% {
      transform: translatey(-15px);
    }
  }
}
</style>