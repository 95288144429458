<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">SITIO WEB</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft pe-md-5" src="../assets/tienda-online1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/tienda-online2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/tienda-online3.gif" style="width: 70%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">Contamos con el mejor equipo para desarrollar tu idea de negocio de una
              manera profesional por medio de sitios web personalizados a tu medida. Todas nuestras páginas están
              desarrolladas con altos estándares de calidad y metodologías modernas que permiten una alta
              velocidad de carga. El precio puede variar dependiendo de la tecnología que se requiera, ofrecemos desde
              sitios autoadministrables desarrollados por medio de CMS (Content Management System) hasta aplicaciones
              web modernas desarrolladas con frameworks tipo SPA (Single-Page Application). También integramos servicios
              de terceros por medio de plugins o API's para que disfrutes de todas sus funcionalidades y beneficios.
              <br> Nuestras páginas web pueden incluir:
            </h3>
            <div class="row">
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Barra de anuncios o descuentos. <br>
                  <i class="fa fa-check color"></i> Correos corporativos. <br>
                  <i class="fa fa-check color"></i> Diseño totalmente personalizado. <br>
                  <i class="fa fa-check color"></i> Formulario de contacto. <br>
                  <i class="fa fa-check color"></i> Galería de fotos (Máx. 50). <br>
                  <i class="fa fa-check color"></i> Secciones con banners de inicio. <br>
                  <i class="fa fa-check color"></i> Sección de productos o servicios. <br>
                  <i class="fa fa-check color"></i> Sección de noticias o blog. <br>
                  <i class="fa fa-check color"></i> Versión movil y de escritorio. <br>
                  <i class="fa fa-check color"></i> Vinculación de redes sociales.
                </h3>
              </div>
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Botón de WhatsApp y llamada. <br>
                  <i class="fa fa-check color"></i> Carrito de compras. <br>
                  <i class="fa fa-check color"></i> Integración de pagos online. <br>
                  <i class="fa fa-check color"></i> Integración de chat tawkto. <br>
                  <i class="fa fa-check color"></i> Integración con Google Analytics. <br>
                  <i class="fa fa-check color"></i> Vinculación de videos. <br>
                  <i class="fa fa-check color"></i> Registro en buscadores y SEO. <br>
                  <i class="fa fa-check color"></i> Hosting y dominio por 1 año incluido. <br>
                  <i class="fa fa-check color"></i> Certificado SSL (Secure Sockets Layer). <br>
                  <i class="fa fa-check color"></i> Capacitación de la página web.
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $3'000.000 <span class="descuento">$3'500.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
        <div class="col-12">
          <h3 style="text-align: center !important; margin-top: 1%;">Conoce algunos de nuestros últimos sitios web:</h3>
          <div class="row justify-content-center">
            <div class="col-12 col-md-3">
              <h3>
                <a target="_blank" class="color2" href="https://www.valeromode.com/"><img class="clientes"
                    src="../assets/pagina-valero.png"></a>
                <a target="_blank" class="color2" href="https://www.celelerestaurante.com/"><img class="clientes"
                    src="../assets/pagina-celele.png"></a>
              </h3>
            </div>
            <div class="col-12 col-md-3">
              <h3>
                <a target="_blank" class="color2" href="https://carlosjaraycia.com/seguros-de-salud/"><img
                    class="clientes" src="../assets/pagina-carlos.png"></a>
                <a target="_blank" class="color2" href="https://www.sirumacoffee.com/"><img class="clientes"
                    src="../assets/pagina-siruma.png"></a>
              </h3>
            </div>
            <div class="col-12 col-md-3">
              <h3>
                <a target="_blank" class="color2" href="https://www.technoapes.co/"><img class="clientes"
                    src="../assets/pagina-technoapes.png"></a>
                <a target="_blank" class="color2" href="https://www.humanizarglobal.com/"><img class="clientes"
                    src="../assets/pagina-humanizar.png"></a>
              </h3>
            </div>
            <div class="col-12 col-md-3">
              <h3>
                <a target="_blank" class="color2" href="https://www.treingenieria.com/"><img class="clientes"
                    src="../assets/pagina-treing.png"></a>
                <a target="_blank" class="color2" href="https://www.sebastianzerrate.com/"><img class="clientes"
                    src="../assets/pagina-zerrate.png"></a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "SitioWeb",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: 170%;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

/* h3 a {
  color: #2fbaff;
}
h3 a:hover {
  color: aqua;
} */

a {
  width: auto;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 130%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 130%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.clientes {
  animation: floatz 3s infinite;
}

@keyframes floatz {
  0% {
    transform: rotateZ(10deg)
  }

  50% {
    transform: rotateZ(-10deg)
  }

  100% {
    transform: rotateZ(10deg)
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color2 {
  animation: color2 0.8s alternate infinite;
}

@keyframes color2 {
  0% {
    color: #d38200;
  }

  100% {
    color: #ffbd2f;
  }
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 460%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    width: 90% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}
</style>