<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">CÓDIGO QR</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft" src="../assets/codigo-qr1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/codigo-qr2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/codigo-qr3.gif" style="width: 50%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">Un código QR es la evolución del código de barras. Es un módulo para
              almacenar información en una matriz
              de puntos o en un código de barras bidimensional. La matriz se lee en el dispositivo móvil por un lector
              específico (lector de QR) y de forma inmediata nos lleva a una aplicación en Internet, un mapa de
              localización, un correo electrónico, una página web o un perfil en una red social.</h3>
            <div class="row">
              <div class="col-12">
                <h3 style="text-align: justify">La estructura general de un código QR es una matriz bidimensional de
                  módulos de dos colores
                  contrastados, en principio blancos y negros. Hay varias versiones de códigos QR según la cantidad de
                  módulos que forman la matriz: van desde la versión 1 (con Na matriz de 21x21 módulos) hasta la
                  versión 10 (con 177x177 módulos). Las versiones de más módulos admiten mayor cantidad de información
                  en el código. Los códigos más extendidos para el uso del público en general suelen ser los de 25x25
                  y de 29x29, para captura desde el teléfono móvil o celular en cualquier situación (paquetes de
                  productos, folletos de mano, tarjetas o carteles de pared).</h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $400.000 <span class="descuento">$500.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "CodigoQr",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

a {
  width: auto;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 100%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 100%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 200%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    width: 70% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}
</style>