<template>
	<div class="container-fluid">
		<div class="row gradient text-center align-items-center">
			<div class="col-12">
				<p>🔔🎊 ¡Descuentos de Lanzamiento! 🎉📣</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Anuncios"
}
</script>

<style scoped>
.container-fluid [class*="col-"] {
	padding: 0;
}

p {
	margin: 0;
	font-weight: bold;
	font-size: 20px;
	color: white;
	text-shadow: -3px -1px 0px black;
}

.gradient {
	background: linear-gradient(45deg, #050096, #0066ff, #2da0ff);
	background-size: 200%;
	animation: gradient 2s ease infinite;
	height: 5vh;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

@media only screen and (max-width: 990px) {
	p {
		font-size: 15px;
	}
}
</style>