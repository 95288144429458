<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">ESTUDIOS ELÉCTRICOS</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft pe-md-4" src="../assets/estudios1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/estudios2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/estudios3.gif" style="width: 70%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">Los estudios eléctricos son análisis detallados y técnicos que se realizan en
              sistemas eléctricos para evaluar su desempeño, seguridad y eficiencia. Estos estudios se llevan a cabo con
              el objetivo de identificar posibles problemas, riesgos o deficiencias en la infraestructura eléctrica, y
              proporcionar recomendaciones para mejorar su funcionamiento. <br>
              Algunos tipos comunes de estudios eléctricos incluyen:</h3>
            <div class="row">
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Estudio de cortocircuito: Evalúa la capacidad de los dispositivos de
                  protección y la infraestructura eléctrica para resistir y manejar condiciones de cortocircuito,
                  identificando puntos de falla y niveles de corriente. <br>
                  <i class="fa fa-check color"></i> Estudio de coordinación de protecciones: Analiza el funcionamiento y
                  la coordinación de los dispositivos de protección en el sistema eléctrico para garantizar que el
                  dispositivo adecuado se active en caso de una falla y proteja la infraestructura sin afectar la
                  operación normal.
                </h3>
              </div>
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Estudio de calidad de energía: Analiza y monitorea la calidad de la
                  energía eléctrica suministrada, identificando problemas como armónicos, fluctuaciones de voltaje,
                  desequilibrios de carga, entre otros.<br>
                  <i class="fa fa-check color"></i> Estudio de puesta a tierra: Evalúa la eficiencia y la seguridad de los
                  sistemas de puesta a tierra, verificando que los niveles de resistencia y continuidad cumplan con las
                  normas y regulaciones aplicables.
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $800.000 <span class="descuento">$1'000.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Trading",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

a {
  font-weight: bold;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 120%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 120%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 250%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    width: 90% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}</style>