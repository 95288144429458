<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
      <div class="container-fluid banner text-center ">
        <div class="row sticky-top justify-content-center pt-5">
          <div class="col-12 col-md-5 ps-md-5">
            <h1 class="floatLeft">DIGITAL</h1>
            <h1 class="floatRight">SOLUTION</h1>
          </div>
          <div class="col-12 col-md-5 pe-md-5">
            <img class="desktop" src="../assets/logo-mini.png">
          </div>
          <div class="col-12">
            <h3>Ofrecemos desarrollos web modernos, diseños gráficos atractivos y soluciones eléctricas profesionales para suplir todas las necesidades de tu empresa o proyecto</h3>
          </div>
          <div class="col-12">
            <a href="#Go" class="btn">Vamos</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Barra Redes Sociales -->
    <div id="Go" class="container-fluid">
      <div class="row gradient-blue text-center align-items-center">
        <div class="col-12">
          <span>
            <a target="_blank" href="https://www.facebook.com/digisolutionpro">
              <img class="redes me-md-5" src="../assets/facebookr.png" alt="Facebook" />
            </a>
            <a target="_blank" href="https://www.instagram.com/digisolutionpro/">
              <img class="redes m-md-4" src="../assets/instagramr.png" alt="Instagram" />
            </a>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=573015315404&text=Informacion">
              <img class="redes ms-md-5" src="../assets/whatsappr.png" alt="WhatsApp" />
            </a>
          </span>
        </div>
      </div>
    </div>
    <!-- Carrusel Banners -->
    <div id="carouselControls" class="carousel slide text-center" data-bs-ride="carousel" data-bs-interval="5000">
      <div class="carousel-inner">
        <div class="carousel-item diseno1 active">
          <h1 class="boxOutIn">Asesoramos marcas que impulsan su crecimiento</h1>
          <h4 class="popOutIn">¡LO HACEMOS POR TI!</h4>
          <p>Creamos la imagen de tu empresa con un diseño profesional logrando resultados excepcionales para clientes
            con productos y servicios visionarios</p>
          <router-link to="/Diseno">
            <button class="btn">Conoce Más</button>
          </router-link>
        </div>
        <div class="carousel-item diseno2">
          <h1 class="boxOutIn">¡Como lo quieres, a tu medida!</h1>
          <h4 class="popOutIn">MEJORA LA CALIDAD DE TUS SERVICIOS</h4>
          <p>Llama la atención de tus consumidores transformando la imagen de tu negocio. Creamos piezas gráficamente
            llamativas y modernas con tiempos de entrega y precios justos</p>
          <router-link to="/Diseno">
            <button class="btn">Conoce Más</button>
          </router-link>
        </div>
        <div class="carousel-item programacion">
          <h1 class="boxOutIn">Creamos el sitio web ideal para tu negocio</h1>
          <h4 class="popOutIn">¡A UN PRECIO JUSTO!</h4>
          <p>Te ayudamos a destacar en el mundo digital por medio de nuestros servicios de diseño gráfico, programación
            web y network marketing</p>
          <router-link to="/Programacion">
            <button class="btn">Conoce Más</button>
          </router-link>
        </div>
        <div class="carousel-item inversiones">
          <h1 class="boxOutIn">¿Tienes dificultades eléctricas?</h1>
          <h4 class="popOutIn">¡TE AYUDAMOS A REALIZARLO!</h4>
          <p>Conoce nuestro gran aliado con el que podrás encontrar diferentes soluciones eléctricas profesionales para tu empresa o proyecto
          </p>
          <router-link to="/Electrica">
            <button class="btn">Conoce Más</button>
          </router-link>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </button>
    </div>
    <!-- Servicios -->
    <div class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">Servicios</h1>
        </div>
      </div>
      <div class="row servicio align-items-center">
        <div class="col-12 col-md-6 fondo">
          <h2>Programación</h2>
          <h4>Construimos tu sitio web o aplicación móvil ideal junto con otras soluciones tecnológicas para optimizar
            al máximo tu negocio</h4>
          <router-link to="/Programacion">
            <button class="btn mt-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner programacion">
        </div>
        <div class="col-12 col-md-6 banner diseño desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Diseño Gráfico</h2>
          <h4>Ofrecemos diseños gráficos a la medida para que tu empresa tenga un aspecto profesional, llamativo y
            moderno</h4>
          <router-link to="/Diseno">
            <button class="btn mt-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner diseño movil">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Ingeniería Eléctrica</h2>
          <h4>Nuestro equipo aliado brinda soluciones innovadoras y eficientes en el diseño, instalación y mantenimiento de sistemas eléctricos</h4>
          <router-link to="/Electrica">
            <button class="btn mt-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner inversiones">
        </div>
      </div>
    </div>
    <!-- Clientes -->
    <div class="container-fluid text-center clientes">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">Nuestros Clientes</h1>
        </div>
      </div>
      <div class="col">
        <h3 class="pt-3 pt-md-5">Conoce algunos de nuestros mejores clientes:</h3>
        <div class="row justify-content-center pb-5 p-md-5">
          <div class="col-12 col-md-3">
            <h3>
              <a target="_blank" class="color2" href="https://www.valeromode.com/"><img class="boxOutIn"
                  src="../assets/pagina-valero.png"></a>
              <a target="_blank" class="color2" href="https://www.humanizarglobal.com/"><img class="boxOutIn"
                  src="../assets/pagina-humanizar.png"></a>
            </h3>
          </div>
          <div class="col-12 col-md-3">
            <h3>
              <a target="_blank" class="color2" href="https://www.celelerestaurante.com/"><img class="boxOutIn"
                  src="../assets/pagina-celele.png"></a>
              <a target="_blank" class="color2" href="https://www.sirumacoffee.com/"><img class="boxOutIn"
                  src="../assets/pagina-siruma.png"></a>
            </h3>
          </div>
          <div class="col-12 col-md-3">
            <h3>
              <a target="_blank" class="color2" href="https://www.technoapes.co/"><img class="boxOutIn"
                  src="../assets/pagina-technoapes.png"></a>
              <a target="_blank" class="color2" href="https://carlosjaraycia.com/seguros-de-salud/"><img class="boxOutIn"
                  src="../assets/pagina-carlos.png"></a>
            </h3>
          </div>
          <div class="col-12 col-md-3">
            <h3>
              <a target="_blank" class="color2" href="https://www.treingenieria.com/"><img class="boxOutIn"
                  src="../assets/pagina-treing.png"></a>
              <a target="_blank" class="color2" href="https://www.sebastianzerrate.com/"><img class="boxOutIn"
                  src="../assets/pagina-zerrate.png"></a>
            </h3>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Inicio",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  height: 82vh;
}

video {
  width: 100%;
  height: 82vh;
  position: absolute;
  object-fit: fill;
}

.banner h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 80px;
}

.floatLeft {
  animation: floatLeft 2s alternate infinite;
}

.floatRight {
  animation: floatRight 2s alternate infinite;
}

.banner img {
  width: auto;
  animation: boxOutIn 2.5s alternate infinite;
}

.banner h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 35px;
  text-align: center;
  margin: auto;
  width: 60%;
}

.banner a {
  width: 10%;
  margin: 3% auto;
}

.gradient-blue {
  background: linear-gradient(45deg, #2da0ff, #0066ff, #050096);
  background-size: 200%;
  animation: gradient 2s ease infinite;
  height: 20vh;
}

.gradient-orange {
  background: linear-gradient(45deg, #df7000, #ff8913, #ffa74f);
  background-size: 200%;
  animation: gradient 2s ease infinite;
  height: 20vh;
}

.gradient-blue img {
  animation: floatZ 2s infinite;
}

.gradient-blue img:hover {
  filter: brightness(0.7) drop-shadow(-4px 3px 0px gray);
}

.carousel-item {
  background-size: 100% 100%;
  height: 80vh;
}

.diseno1 {
  background-image: url("../assets/banner-diseno1.png");
}

.diseno1 h1 {
  width: 60%;
  margin: 4.5% 0 0 23%;
}

.diseno1 h4 {
  width: 40%;
  margin: 2.5% 0 0 30%;
}

.diseno1 p {
  width: 50%;
  margin: 1.5% 0 0 25%;
}

.diseno1 .btn {
  margin-top: 2%;
}

.diseno2 {
  background-image: url("../assets/banner-diseno2.png");
}

.diseno2 h1,
.inversiones h1 {
  width: 45%;
  margin: 3% 0 0 50%;
}

.diseno2 h4,
.inversiones h4 {
  width: 45%;
  margin: 2.5% 0 0 48%;
}

.diseno2 p,
.inversiones p {
  width: 40%;
  margin: 1.5% 0 0 50%;
}

.diseno2 .btn,
.inversiones .btn {
  margin: 1.5% 0 0 41%;
}

.programacion {
  background-image: url("../assets/banner-programacion1.png");
}

.programacion h1 {
  width: 50%;
  margin: 3% 0 0 10%;
}

.programacion h4 {
  width: 40%;
  margin: 2.5% 0 0 30%;
}

.programacion p {
  width: 50%;
  margin: 1.5% 0 0 25%;
}

.programacion .btn {
  margin-top: 2%;
}

.inversiones {
  background-image: url("../assets/banner-inversiones1.jpg");
}

.carousel-item p {
  color: white;
  font-size: 20px;
}

.banner {
  height: 50vh;
}

.servicio h2 {
  font-size: 50px;
}

.servicio .fondo,
.servicio .banner {
  background-image: url(../assets/servicio-fondo.png);
  background-size: 100% 100%;
  height: 50vh;
  padding: 2%;
}

.servicio .fondo {
  animation: service 1s infinite;
}

.servicio .diseño {
  background-image: url(../assets/diseno.gif);
  animation: zoom 3.3s infinite;
}

.servicio .programacion {
  background-image: url(../assets/programacion.gif);
  animation: zoom 3.6s infinite;
}

.servicio .inversiones {
  background-image: url(../assets/electrica.gif);
  animation: zoom 3.9s infinite;
}

.clientes {
  background-image: url(../assets/servicio-fondo.png);
  background-size: 100% 100%;
  animation: service 1s infinite;
}

@media only screen and (max-width: 990px) {
  #wrapper {
    background-image: url(../assets/banner-inicio-movil.png);
    align-items: top;
    text-align: center;
    display: flex;
  }

  .banner h1 {
    font-size: 50px;
  }

  .banner h3 {
    font-size: 25px;
    margin: 10% auto;
    width: 80%;
  }

  .banner a {
    width: auto;
    display: inline;
  }

  .gradient-blue img {
    width: 15%;
    margin: auto 5%;
  }

  .carousel-item h1,
  .carousel-item h4,
  .carousel-item p {
    margin: 5% auto;
    width: 80%;
  }

  .carousel-item h1 {
    font-size: 35px;
  }

  .carousel-item .btn {
    margin: 0 auto;
    width: auto;
    display: inline;
  }

  .servicio h2 {
    font-size: 40px !important;
    padding-top: 8%;
  }

  .servicio .diseño {
    background-size: cover;
    background-position: 20%;
  }

  .diseno1 {
    background-image: url("../assets/banner-diseno1-movil.png");
  }

  .diseno2 {
    background-image: url("../assets/banner-diseno2-movil.png");
  }

  .programacion {
    background-image: url("../assets/banner-programacion1-movil.png");
  }

  .inversiones {
    background-image: url("../assets/banner-inversiones1-movil.jpg");
  }

  .servicio .programacion {
    background-size: cover;
    background-position: 40%;
  }
}
</style>