<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <div class="container">
        <div class="row pt-5">
          <div class="col-12 col-md-8 pt-4">
            <h1>DISEÑO MÓVIL UI/UX</h1>
            <h3>El diseño móvil esta enfocado en el usuario, en lo que este quiere hacer con la aplicación móvil o en la
              pantalla. Trabajamos en la experimentación antes, durante y después de interactuar con la pantalla de su
              móvil llevando en el diseño toda la experiencia del usuario en la web aplicada a su dispositivo móvil.
            </h3>
            <div class="row">
              <div class="col-6">
                <h3>• Diseño totalmente personalizado. <br>
                  • 5 Secciones con banners de inicio. <br>
                  • Versión movil y de escritorio. <br>
                  • Formulario de contacto. <br>
                  • Sección de productos o servicios.</h3>
              </div>
              <div class="col-6">
                <h3>• Sección de noticias o blog. <br>
                  • Carrito de compras. <br>
                  • Barra de anuncios o descuentos. <br>
                  • Botón de Whatsapp y llamada.</h3>
              </div>
            </div>
            <br>
            <div class="row justify-content-evenly mb-5">
              <div class="col-12 col-md-6">
                <h3 class="precio">PRECIO DESDE: USD $1499 <span class="descuento">$1700</span></h3>
              </div>
              <div class="col-12 col-md-6">
                <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-5 float">
            <img src="../assets/diseno-movil.png">
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "DisenoMovil",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  background-image: url(../assets/banner-inicio.png);
  background-attachment: fixed;
  background-size: 100% 100%;
  min-height: 82vh
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  text-align: justify;
  width: 90%;
}

a {
  width: auto;
}

.float {
  rotate: 45deg;
  animation: float 3s ease-in-out infinite;
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin: 0 !important;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

@keyframes float {
  0% {
    transform: skew(10deg) translateY(-20px);
  }

  50% {
    transform: skew(5deg, 5deg) translateY(40px) rotateX(25deg);
  }

  100% {
    transform: skew(10deg) translateY(-20px);
  }
}

@media only screen and (max-width: 990px) {
  #wrapper {
    background-image: url(../assets/banner-inicio-movil.png);
    background-size: 100% 100%;
    height: auto;
    align-items: center;
    text-align: center;
    display: flex;
  }

  .container h1 {
    font-size: 40px;
    padding: 0;
    margin: 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .float img {
    width: 100%;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }

  @keyframes float {
    0% {
      transform: translatey(-15px);
    }

    50% {
      transform: translatey(-35px);
    }

    100% {
      transform: translatey(-15px);
    }
  }
}
</style>