<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid mx-md-5">
      <router-link to="/">
        <img class="navbar-brand-mini desktop spin" src="@/assets/logo-mini.png" alt="Logo" />
        <img class="navbar-brand desktop" src="@/assets/logo1.png" alt="Logo" />
        <img class="navbar-brand movil spin" src="@/assets/logo-mini.png" alt="Logo" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
        aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fa fa-bars" style="color: black"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav navbar-nav-scroll" style="--bs-scroll-height: 150px">
          <li class="nav-item">
            <router-link to="/">
              <a class="nav-link">Inicio</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Programacion">
              <a class="nav-link">Programación</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Diseno">
              <a class="nav-link">Diseño</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Electrica">
              <a class="nav-link">Eléctrica</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/Contacto">
              <a class="nav-link">Contacto</a>
            </router-link>
          </li>
        </ul>
        <span style="margin-left: auto">
          <a target="_blank" href="https://www.facebook.com/digisolutionpro">
            <img class="redes" src="../assets/facebook.png" alt="Facebook" />
          </a>
          <a target="_blank" href="https://www.instagram.com/digisolutionpro/">
            <img class="redes mx-md-3" src="../assets/instagram.png" alt="Instagram" />
          </a>
          <a target="_blank" href="https://api.whatsapp.com/send?phone=573015315404&text=Informacion">
            <img class="redes" src="../assets/whatsapp.png" alt="WhatsApp" />
          </a>
        </span>
      </div>
    </div>
    <div class="btn-scrolltop" id="btn_scrolltop">
      <i class="fa fa-arrow-circle-o-up"></i>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {
    const btn_scrolltop = document.getElementById("btn_scrolltop")
    btn_scrolltop.addEventListener('click', () => {
      window.scrollTo(0, 0)
    })
    window.onscroll = () => {
      add_btn_scrolltop()
    }
    const add_btn_scrolltop = () => {
      if (window.scrollY < 300) {
        btn_scrolltop.classList.remove("btn-scrolltop-on")
      } else {
        btn_scrolltop.classList.add("btn-scrolltop-on")
      }
    }
  }
};
</script>

<style scoped>
.navbar {
  background: linear-gradient(45deg, #ff9327, #ff840a, #df7000);
  animation: gradient 2s ease infinite;
  background-size: 200%;
  min-height: 13vh;
  padding: 0;
}

.navbar-brand-mini {
  width: 20%;
  margin-right: 5%;
}
.navbar-brand {
  padding: 0;
}

a {
  width: 28%;
}

.spin {
  animation: spin 5s linear infinite;
}

.nav-link {
  font-family: "Century Schoolbook";
  font-weight: bold;
  font-size: 20px;
  color: white !important;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #512c00 !important;
}

.btn-scrolltop {
  font-size: 70px;
  position: fixed;
  bottom: 0;
  left: 1.5%;
  z-index: 10;
  cursor: pointer;
  color: #df7000;
  transform: translateY(100px);
  transition: 0.3s;
}

.btn-scrolltop-on {
  transform: translateY(0);
}

.redes {
  width: 15%;
  filter: drop-shadow(-4px 3px 0px rgb(0, 0, 0, 0.5)) drop-shadow(-3px 3px 0px #ec5b00);
}

@media only screen and (max-width: 990px) {
  .btn-scrolltop {
    color: rgb(223, 112, 0, 0.8);
    left: 3%;
  }

  .navbar {
    text-align: center;
  }

  .navbar-brand {
    width: 90%;
    margin-left: 2%;
  }

  .nav-link {
    line-height: 0.7;
    font-size: 18px;
    width: auto;
  }

  span {
    color: white !important;
    margin: 0 !important;
  }

  .redes {
    width: 10%;
    margin: 3% !important;
  }
}
</style>
