<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <video src="../assets/programacion.mp4" type="video" autoplay loop muted></video>
      <div class="banner text-center">
        <div class="sticky-top">
          <h1>PROGRAMACIÓN</h1>
          <br>
          <h3>Digitaliza tu negocio mediante un sitio web o tienda online profesional. También creamos aplicaciones
            móviles llamativas y códigos QR acorde a tus necesidades</h3>
          <a href="#Go" class="btn">Vamos</a>
        </div>
      </div>
    </div>
    <!-- Que es Programación -->
    <div id="Go" class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">¿Que es Programación?</h1>
        </div>
      </div>
      <div class="row servicio align-items-center ">
        <div class="col-12 col-md-6 fondo">
          <h4 class="mt-3">La Programación es el proceso al que se recurre para crear algún tipo de aplicación o
            software, para materializar un concepto o proyecto que requiere de la utilización de un lenguaje informático
            para poder llevarse a cabo. Es algo que cada vez está cobrando más importancia dentro del mundo del
            marketing digital</h4>
        </div>
        <div class="col-12 col-md-6" style="margin: 0; padding: 0; height: 356px;">
          <iframe width="100%" height="100%" allowfullscreen src="https://www.youtube.com/embed/pWw4UtQhdek">
          </iframe>
        </div>
      </div>
    </div>
    <!-- Servicios de Programación -->
    <div class="container-fluid text-center">
      <div class="row gradient-orange align-items-center">
        <div class="col-12">
          <h1 class="flipX">Servicios de Programación</h1>
        </div>
      </div>
      <div class="row servicio align-items-center">
        <div class="col-12 col-md-6 fondo">
          <h2>Sitio Web</h2>
          <h4>Construimos el sitio web de tus sueños para que ofrezcas tus productos o servicios de una manera
            moderna y profesional</h4>
          <router-link to="/SitioWeb">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner programacion-web">
        </div>
        <div class="col-12 col-md-6 banner landing-page desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Landing Page</h2>
          <h4>Creamos un mini sitio web en el cual puedes exponer toda tu idea de negocio
            o emprendimiento en una sola página</h4>
          <router-link to="/LandingPage">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner landing-page movil">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Aplicación Móvil</h2>
          <h4>Programamos la aplicación móvil ideal para que desarrolles tu idea de negocio de una manera moderna y a tu
            medida</h4>
          <router-link to="/AplicacionMovil">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner programacion-movil">
        </div>
        <div class="col-12 col-md-6 banner codigo-qr desktop">
        </div>
        <div class="col-12 col-md-6 fondo">
          <h2>Código QR</h2>
          <h4>Creamos códigos QR personalizados con tu propósito y estilo para que estés siempre al
            alcance de todas las cámaras fotográficas</h4>
          <router-link to="/CodigoQr">
            <button class="btn mt-2 mt-md-3">Conoce Más</button>
          </router-link>
        </div>
        <div class="col-12 col-md-6 banner codigo-qr movil">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Programacion",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  overflow: hidden;
  height: 82vh
}

video {
  width: 100%;
  height: 82vh;
  filter: brightness(70%);
  position: absolute;
  object-fit: fill;
}

.banner h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 80px;
  padding-top: 7%;
  animation: zoomFont 1s alternate infinite;
}

.banner h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 35px;
  margin: 3% auto;
  width: 70%;
}

.banner a {
  width: 10%;
  margin-top: 2%;
}

.gradient-orange {
  background: linear-gradient(45deg, #df7000, #ff8913, #ffa74f);
  background-size: 200%;
  animation: gradient 2s ease infinite;
  height: 20vh;
}

.gradient-orange h1 {
  font-size: 40px;
}

.banner {
  height: 50vh;
}

.servicio h2 {
  font-size: 50px;
  padding-top: 3%;
}

.servicio .fondo,
.servicio .banner {
  background-image: url(../assets/servicio-fondo.png);
  background-size: 100% 100%;
  height: 50vh;
  padding: 2%;
}

.servicio .fondo {
  animation: service 1s infinite;
}

.servicio .programacion-web {
  background-image: url(../assets/servicio-programacion-web.gif);
  animation: zoom 3.2s infinite;
}

.servicio .landing-page {
  background-image: url(../assets/servicio-landing-page.gif);
  animation: zoom 3.4s infinite;
}

.servicio .programacion-movil {
  background-image: url(../assets/servicio-aplicacion-movil.gif);
  animation: zoom 3s infinite;
}

/* .servicio .integracion-api {
  background-image: url(../assets/servicio-integracion-api.gif);
  animation: zoom 3.6s infinite;
} */

.servicio .codigo-qr {
  background-image: url(../assets/servicio-codigo-qr.gif);
  animation: zoom 3.8s infinite;
}

@media only screen and (max-width: 990px) {
  #wrapper {
    align-items: center;
    display: flex;
  }

  .banner h1 {
    font-size: 35px;
    padding: 0;
    margin-top: -10%;
  }

  .banner h3 {
    font-size: 23px;
    margin: 10% auto;
    width: 90%;
  }

  .banner a {
    display: inline;
  }

  .flipX {
    font-size: 35px !important;
  }

  .servicio h2 {
    font-size: 40px !important;
    line-height: 1;
    padding-top: 8%;
  }

  .servicio .programacion-web {
    background-image: url(../assets/servicio-programacion-web2.gif);
  }

  .servicio .programacion-movil {
    background-image: url(../assets/servicio-aplicacion-movil2.gif);
  }

  .mt-3 {
    margin-top: 15% !important;
  }
}
</style>