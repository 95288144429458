<template>
  <div>
    <Anuncios />
    <Navbar />
    <!-- Banner Principal -->
    <div id="wrapper">
      <div class="container">
        <div class="row pt-5">
          <div class="col-12 col-md-8 pt-4">
            <h1>PLAN DE COMPENSACIÓN BYDZYNE</h1>
            <h3>ByDzyne es una compañía de MLM con la que puedes ganar dinero vendiendo el producto al por menor y
              manteniendo la diferencia entre los costos y otros beneficios.
              También puedes ganar dinero creando un equipo de otros miembros afiliados que muchas personas refieren a
              una línea descendente.
              Cuantos más niveles y miembros reclutes tú y tu equipo en la oportunidad ByDzyne, mayor será el rango para
              el que podrás calificar y más dinero podrás ganar.</h3>
            <div class="row">
              <div class="col-12">
                <h3>Cómo ganas dinero con ByDzyne
                  Tú obtienes ingresos residuales cuando los miembros de tu línea descendente recompran los productos
                  cada mes y ganas un porcentaje de la compra de sus productos.
                  En el siguiente video te explican cómo funciona el plan de compensación en su totalidad:
                </h3>
                <iframe width="750" height="350" src="https://www.youtube.com/embed/ygBLGKvDmno">
                </iframe>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <h3>Cuál es el costo de unirse a esta compañía?
                  En este momento, el costo de unirse es de $19,95 más la compra de un paquete mensual o anual
                  que va desde los $219 hasta los $1500. Dependiendo de qué tipo de paquete elijas podrás acceder
                  a diversos tipos de descuentos en los productos de ByDzyne.
                  En este momento, la compañía ofrece 6 paquetes con los que puedes comenzar, los cuales son: <br>
                  • Paquete SMA Mensual: este paquete de producto y kit costará $219 para comenzar. <br>
                  • Paquete PES Mensual: este paquete de producto y kit costará $299 para comenzar. <br>
                  • Paquete PES Anual: este paquete de producto y kit costará $649 para comenzar. <br>
                  • PES y SMA Combo: este paquete de producto y kit costará $860 para comenzar. <br>
                  • Promoción Tecnología y digital: este paquete y kit costará $999 para comenzar. <br>
                  • Promoción SMA Anual: este paquete y kit costará $1500 para comenzar. <br>
                  ¡No esperes mas y haz parte de esta excelente compañia! Contactanos para empezar cuanto antes.
                </h3>
              </div>
            </div>
            <br>
            <div class="row justify-content-evenly mb-5">
              <div class="col-12 col-md-6">
                <h3 class="precio">PRECIO DESDE: USD $219 <span class="descuento">$250</span></h3>
              </div>
              <div class="col-12 col-md-6">
                <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-5 float">
            <img src="../assets/compensacion.png">
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Compensacion",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
#wrapper {
  background-image: url(../assets/banner-inicio.png);
  background-attachment: fixed;
  background-size: 100% 100%;
  min-height: 82vh
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  text-align: justify;
  width: 90%;
}

a {
  width: auto;
}

.float {
  margin-top: 10% !important;
  rotate: 45deg;
  animation: float 3s ease-in-out infinite;
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin: 0 !important;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

@keyframes float {
  0% {
    transform: skew(10deg) translateY(-20px);
  }

  50% {
    transform: skew(5deg, 5deg) translateY(40px) rotateX(25deg);
  }

  100% {
    transform: skew(10deg) translateY(-20px);
  }
}

@media only screen and (max-width: 990px) {
  #wrapper {
    background-image: url(../assets/banner-inicio-movil.png);
    background-size: 100% 100%;
    height: auto;
    align-items: center;
    text-align: center;
    display: flex;
  }

  .container h1 {
    font-size: 40px;
    padding: 0;
    margin: 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .float img {
    width: 100%;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }

  @keyframes float {
    0% {
      transform: translatey(-15px);
    }

    50% {
      transform: translatey(-35px);
    }

    100% {
      transform: translatey(-15px);
    }
  }
}
</style>