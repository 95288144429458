<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">PANELES SOLARES</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft pe-5" src="../assets/paneles1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/paneles2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/paneles3.gif" style="width: 70%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">Los paneles solares también conocidos como módulos fotovoltaicos, son
              dispositivos que capturan la energía del sol y la convierten en energía eléctrica utilizable. Están
              compuestos por celdas fotovoltaicas que contienen materiales semiconductores, como el silicio, que generan
              electricidad cuando los fotones de la luz solar golpean la superficie del panel. <br>
              Las ventajas de los paneles solares son:</h3>
            <div class="row">
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Energía renovable y sostenible: La energía solar es una fuente
                  inagotable, ya que proviene del sol y se considera una forma de energía limpia. <br>
                  <i class="fa fa-check color"></i> Incentivos y programas de subsidios: En muchos países, existen
                  incentivos gubernamentales, como créditos fiscales, subvenciones y programas de financiamiento, que
                  facilitan la adopción de paneles solares. <br>
                  <i class="fa fa-check color"></i> Baja mantenimiento: Los paneles solares requieren poco mantenimiento.
                  Solo es necesario limpiarlos periódicamente para asegurar un rendimiento óptimo.
                </h3>
              </div>
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Aplicaciones versátiles: Los paneles solares se pueden utilizar en una
                  variedad de aplicaciones, desde sistemas residenciales y comerciales hasta aplicaciones industriales. <br>
                  <i class="fa fa-check color"></i> Ahorro en costos de energía: Al generar electricidad a partir de la
                  luz solar, se puede reducir significativamente la factura de energía eléctrica. <br>
                  <i class="fa fa-check color"></i> Independencia energética: Al generar electricidad propia a partir de
                  la luz solar, se puede lograr una mayor independencia energética.
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $5'000.000 <span class="descuento">$6'000.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Paneles",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

a {
  font-weight: bold;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 135%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 120%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 270%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    padding: 0 !important;
    width: 100% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}</style>