<template>
  <div>
    <Anuncios />
    <Navbar />
    <video src="../assets/inicio.mp4" type="video" autoplay loop muted></video>
    <div class="container text-center">
      <div class="row pt-5">
        <h1 class="flipX">SUBESTACIONES</h1>
      </div>
      <div class="row pb-4">
        <div class="col-12 col-md-4 pe-md-5 my-auto">
          <div class="row">
            <div class="col-6">
              <img class="floatLeft pe-md-4" src="../assets/subestacion1.gif">
            </div>
            <div class="col-6">
              <img class="floatUp" src="../assets/subestacion2.gif">
            </div>
          </div>
          <div class="row my-4 ">
            <div class="col">
              <img class="floatRight" src="../assets/subestacion3.gif" style="width: 70%">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="card">
            <h3 style="text-align: justify">as subestaciones eléctricas son instalaciones que se utilizan en el sistema de
              distribución de energía eléctrica para transformar, regular y distribuir la electricidad a diferentes
              niveles de tensión. Son puntos de conexión entre las redes de transmisión y las redes de distribución, y su
              principal función es recibir la energía eléctrica de alta tensión proveniente de la red de transmisión y
              transformarla a una tensión más adecuada para su distribución a los consumidores. <br>
              Algunos de los usos principales de las subestaciones eléctricas son:</h3>
            <div class="row">
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Regulación de voltaje y estabilidad de la red: Las subestaciones
                  también desempeñan un papel crucial en la regulación del voltaje y la estabilidad de la red eléctrica,
                  ya que pueden controlar y ajustar la tensión en diferentes puntos de la red, garantizando así un
                  suministro eléctrico estable y de calidad. <br>
                  <i class="fa fa-check color"></i> Interconexión de redes eléctricas: Las subestaciones también se
                  utilizan para interconectar diferentes redes eléctricas, permitiendo la transferencia de energía entre
                  regiones o países, facilitando así el intercambio y la diversificación de la oferta energética.
                </h3>
              </div>
              <div class="col-6">
                <h3>
                  <i class="fa fa-check color"></i> Transformación de tensión: Las subestaciones eléctricas permiten
                  convertir la electricidad de alta tensión utilizada en la red de transmisión a niveles de tensión más
                  bajos y adecuados para la distribución a los hogares, comercios e industrias. <br>
                  <i class="fa fa-check color"></i> Distribución de energía: Las subestaciones eléctricas distribuyen la
                  energía eléctrica transformada a través de líneas de distribución a los puntos de consumo, asegurando
                  que la electricidad llegue de manera segura y confiable a los usuarios finales.
                </h3>
              </div>
            </div>
          </div>
          <div class="row mt-md-4">
            <div class="col-12 col-md-6 boxOutIn">
              <h3 class="precio">PRECIO DESDE: <br> COP $50'000.000 <span class="descuento">$$60'000.000</span></h3>
            </div>
            <div class="col-12 col-md-6">
              <a class="btn" data-bs-toggle="modal" data-bs-target="#servicioModal">Solicitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Anuncios from "../components/Anuncios.vue";
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "Subestaciones",
  components: {
    Anuncios,
    Navbar,
    Footer,
  }
};
</script>

<style scoped>
video {
  width: 100%;
  height: 125%;
  position: absolute;
  object-fit: fill;
  z-index: -1;
}

h1 {
  text-shadow: -7px -2px 2px #000855;
  font-size: 50px;
  margin-bottom: 3%;
}

h3 {
  text-shadow: -4px -2px 2px #000855;
  font-size: 20px;
  font-weight: normal;
  text-align: justify;
}

a {
  font-weight: bold;
}

b {
  color: #fcc69a;
}

.floatLeft {
  animation: floatLeft 1.2s alternate infinite;
  width: 120%
}

.floatUp {
  animation: floatUp 1.6s alternate infinite;
  width: 120%
}

.floatRight {
  animation: floatRight 1.4s alternate infinite;
}

.card {
  padding: 3%;
  background-color: rgb(252, 165, 4, 0.4);
  border-radius: 25px;
  animation: backcolor 1s alternate infinite;
}

@keyframes backcolor {
  0% {
    background-color: rgb(252, 165, 4, 0.4);
  }

  100% {
    background-color: rgb(252, 165, 4, 0.5);
  }
}

.precio {
  padding: 4%;
  text-align: center;
  background-color: rgb(223, 112, 0, 0.9);
  border: solid 2px;
  border-radius: 25px;
  width: 80%;
  margin-left: 10%;
  animation: color 0.5s infinite;
}

@keyframes color {
  0% {
    border-color: green;
  }

  50% {
    border-color: white;

  }

  100% {
    border-color: greenyellow;
  }
}

.color {
  animation: colorFont 0.5s alternate infinite;
}

@keyframes colorFont {
  0% {
    color: green;
  }

  100% {
    color: greenyellow;
  }
}

@media only screen and (max-width: 990px) {
  video {
    height: 270%;
  }

  .container h1 {
    font-size: 40px;
    padding: 5% 0;
  }

  .container h3 {
    font-size: 18px;
    margin: 5% auto !important;
    width: 90%;
    text-align: center;
  }

  .container a {
    margin: 0 !important;
  }

  .card {
    padding: auto 5%;
  }

  .floatLeft,
  .floatUp {
    width: 90% !important;
  }

  .col-6 {
    padding: 0;
  }

  .pt-5 {
    padding: 0 !important;
  }
}</style>